@font-face {
	font-family: "Harry";
	src: url("./assets/fonts/HARRYP__.TTF");
}
@font-face {
	font-family: "Dumb";
	src: url('./assets/fonts/dum3.ttf');
}
@font-face {
	font-family: "Hangyaboly";
	src: url('./assets/fonts/Hangyaboly.ttf');
}
@font-face {
	font-family: "magic_school";
	src: url('./assets/fonts/MagicSchoolOne.ttf');
}
@font-face {
	font-family: "lumos";
	src: url('./assets/fonts/LUMOS.TTF');
}

html, body { 
	background: url(./assets/img/541378.jpg) no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.img-bg-firts{
	background: url(./assets/img/bg_steeps.jpg) no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.img-bg-post{
	background: url(./assets/img/hwrpost.jpg) no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.content-hp{
	display: flex;
	justify-content: center;
	align-items: center;
}

.title-hp {
	color: #FFFFFF;
	font-family: "Hangyaboly";
	font-size: 5.2em;
}
.text-hp {
	color: #32291b;
	font-family: "magic_school";
	font-size: 2.8em;
	letter-spacing: 1px;
	text-shadow: 4px 5px 3px #5D5D5D;
}
.text-family {
	color: white;
	font-family: "Dumb";
	font-size: 2.5em;
	text-shadow: 4px 5px 3px #000000;
}
.text-hp-hm {
	color: #000000;
	font-family: "Hangyaboly";
	font-size: 4.2em;
	top: 2px;
	text-shadow: 4px 5px 3px #5D5D5D;
}
.text-hp-y {
	color: #2A623D;
	font-family: "Hangyaboly";
	font-size: 2.8em;
  	font-weight: bold;
	text-shadow: 4px 5px 3px #000000;
}
.text-hp-big{
  	color: #1A472A;
	font-family: "Harry";
	font-size: 5.2em;
  	font-weight: bolder;
	letter-spacing: -2px;
	text-shadow: 4px 5px 3px #AAAAAA;
	
}
.p-post {
	font-family: "Harry";
	font-size: 5.2em;
}
.main-carta {
	min-height: 100vh;
	min-width: 100vw;
	font-family: "Dumb";
	font-size: 3.2em;
	background: url(./assets/img/post_.jpg) no-repeat; 
	background-attachment:fixed;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;

}

.modal-dialog {
    background-image: url(./assets/img/post_.jpg);
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
}
.moldal{
	background:#000000
}
.modal-content{
	background-color: #fff0;
}
.title-hp-md{
	color: white; 
	font-family: "Dumb";
	font-size: 3.2em;
}
.title-danwtss{
	color: white; 
	font-family: "Dumb";
	font-size: 1.1em;
	background: #000000b5;
}
.title-saying{
	color: rgb(0, 0, 0); 
	font-family: "Dumb";
	font-size: 2.1em;
}
.title-nhp{
	color: white; 
}
.img-p{
	width: 100%;
}

.logo-mg{
	width: 250px;
}
.card{
	min-height: 400px;
}
.bg-ultra{
	background: #353232ad;
}

.text-modal {
	font-size: 2em;
	color: #ffffff;
	text-align: center;
}

.img-tem{
	position: relative;
    display: inline-block;
    text-align: center;
}
.text-onimg{
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.card-title-tem {
	position: absolute;    /* posición absolute con respecto al padre */
	right: 1rem;
}
.imgx{
	height: 16rem;
	width: 11rem;
	border-radius: 10px;
	border: 5px solid black;
}
.card-back{
	background-color: #f9b0de
}
.text-card-d{
	color: #000000;
	font-family: "Hangyaboly";
	font-size: 2.5em;
}

.class-art-s{
	position: absolute;
	z-index: 1px;

}

.counter-mx{
	color: #eceaea;
	font-family: "Hangyaboly";
	font-size: 3.2em;
	letter-spacing: -2px;
	text-shadow: 5px 5px 3px #1A472A;
}
.info-extra{
	position: absolute;
	left: 0;
	cursor: pointer;
}

@media screen and (max-width:1000px){
	.info-extra {
		width: 85px;
	}
}